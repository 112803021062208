.main,
.stack {
  margin-top: var(--space-xl-2xl);
}

.main > * + * {
  margin-top: var(--space-3xl);
}

/* RESPONSIVE */
@media (--medium) {
  .main,
  .stack {
    margin-top: var(--space-2xl-3xl);
  }
}
